<template>
  <div>
    <b-button
      class="mb-1"
      variant="flat-primary"
      @click="$router.go(-1)"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Voltar</span>
    </b-button>
    <b-tabs
      horizontal
      content-class="col-12 col-md-12 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-12 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="AirplayIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Analytics</span>
        </template>
        <section id="card-actions">
          <b-row>
            <b-col md="3" />
            <b-col>
              <div style="display: flex; justify-content: center; align-items: center;">
                <b-card
                  style="max-width: 100%; height: 60px; margin: 0 auto; background-color: #7367EF; margin-bottom: 30px;"
                  class="font-weight-bold"
                >
                  <h4 style="color: #FFFFFF; display: flex; justify-content: center; align-items: center; margin: 0;">
                    {{ empresa.nomeFantasia }}
                  </h4>
                </b-card>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <!-- card filtros  -->
            <b-col md="3">
              <card-filtros @informacao-para-pai="updateReceivedData" />
            </b-col>
            <!-- card statistic -->
            <b-col md="9">
              <b-col md="12">
                <b-overlay
                  :show="show"
                  rounded="sm"
                >
                  <card-statistics :data="statisticsItems" />
                </b-overlay>

              </b-col>
              <b-col md="12">
                <b-overlay
                  :show="show"
                  rounded="sm"
                >
                  <card-status-o-s :data="receivedData.totalizadores_status" />
                </b-overlay>
              </b-col>
              <b-row>
                <b-col md="6">
                  <b-overlay
                    :show="show"
                    rounded="sm"
                  >
                    <card-totalizadores-estado :filtro="filtros" />
                  </b-overlay>
                </b-col>
                <b-col md="6">
                  <b-overlay
                    :show="show"
                    rounded="sm"
                  >
                    <card-totalizadores-cidade :filtro="filtros" />
                  </b-overlay>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </section>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Informações Gerais</span>
        </template>
        <EmpresasGeral :general-data="empresa" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Administradores</span>
        </template>
        <AdministradoresGeral />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="LayersIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Faturas</span>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BButton, BRow, BCol, BCard, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import EmpresasGeral from './cards/EmpresasGeral.vue'
import AdministradoresGeral from './cards/AdministradoresGeral.vue'
import CardFiltros from './cards/CardFiltros.vue'
import CardStatistics from '../dashboard/cards/CardStatistics.vue'
import CardStatusOS from './cards/StatusOS.vue'
import CardTotalizadoresCidade from './cards/TotalizadoresCidade.vue'
import CardTotalizadoresEstado from './cards/TotalizadoresEstado.vue'
import axios from '../../../axios-auth'

export default {
  components: {
    BOverlay,
    EmpresasGeral,
    AdministradoresGeral,
    CardFiltros,
    CardTotalizadoresCidade,
    CardTotalizadoresEstado,
    CardStatistics,
    CardStatusOS,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filtros: {},
      show: true,
      receivedData: '',
      loadParametro: false,
      loadCredor: false,
      loadCliente: false,
      loadRemessa: false,
      clienteId: null,
      empresa: {
        id: null,
        nomeFantasia: null,
        razaoSocial: null,
        cnpj: null,
        inscricaoEstadual: null,
        telefone: null,
        celular: null,
        status: null,
        cep: null,
        endereco: null,
        bairro: null,
        cidade: null,
        uf: null,
      },
      dash: '',
      statisticsItems: [
        {
          icon: 'DollarSignIcon',
          color: 'light-info',
          title: '',
          subtitle: 'CMR',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'CMG',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'TAT',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-success',
          title: '',
          subtitle: 'PT',
          customClass: '',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-info',
          title: '',
          subtitle: 'Reparabilidade',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Reincidência',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'REC',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-success',
          title: '',
          subtitle: 'LMI',
          customClass: '',
        },
      ],
    }
  },
  async mounted() {
    await axios.get(`api/empresas/edit/${this.$route.params.id}`).then(res => {
      this.empresa.id = res.data.dados.id
      this.empresa.nomeFantasia = res.data.dados.nome_fantasia
      this.empresa.razaoSocial = res.data.dados.razao_social
      this.empresa.cnpj = this.soNumero(res.data.dados.cnpj)
      this.empresa.inscricaoEstadual = res.data.dados.inscricao_estadual
      this.empresa.telefone = this.soNumero(res.data.dados.telefone)
      this.empresa.celular = res.data.dados.celular
      this.empresa.status = res.data.dados.status
      this.empresa.cep = this.soNumero(res.data.dados.cep)
      this.empresa.endereco = res.data.dados.endereco
      this.empresa.bairro = res.data.dados.bairro
      this.empresa.cidade = res.data.dados.cidade
      this.empresa.uf = res.data.dados.uf
      this.loadCredor = true
    })
  },
  methods: {
    updateReceivedData(data) {
      if (data === 1) {
        this.show = true
      } else if ('empresa_id' in data) {
        this.filtros = data
      } else {
        this.show = false
      }
      this.statisticsItems[0].title = `R$ ${data.indicadores.cmr}`
      this.statisticsItems[1].title = `R$ ${data.indicadores.cmg}`
      this.statisticsItems[2].title = `${data.indicadores.tat} dias`
      this.statisticsItems[3].title = `${data.indicadores.pt}%`
      this.statisticsItems[4].title = `${data.indicadores.rep}%`
      this.statisticsItems[5].title = `${data.indicadores.rein}%`
      this.statisticsItems[6].title = `${data.indicadores.rec}%`
      this.statisticsItems[7].title = data.indicadores.lmi
      // Atualizar o valor da variável 'receivedData' com os dados recebidos do componente filho
      this.receivedData = data
    },
    soNumero(valor) {
      return valor.replace(/\D/g, '')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
