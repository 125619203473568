<template>
  <b-card>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="8">
          <b-form-group
            label="Nome"
            label-for="nome"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.nomeFantasia"
                name="nome"
                placeholder="name"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.nomeFantasia"
                name="nome"
                placeholder="nome"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="CPF/CNPJ"
            label-for="cpf-cnpj"
          >
            <div v-if="editar">
              <cleave
                v-model="dadoCliente.cnpj"
                class="form-control"
                :raw="false"
                :options="mask.cnpj"
                name="cpf-cnpj"
                placeholder="CPF/CNPJ"
              />
            </div>
            <div v-else>
              <cleave
                v-model="dadoCliente.cnpj"
                class="form-control"
                :raw="false"
                :options="mask.cnpj"
                name="cpf-cnpj"
                placeholder="CPF/CNPJ"
                :readonly="!editar"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="8">
          <b-form-group
            label="Razão Social"
            label-for="razao-social"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.razaoSocial"
                name="razao-social"
                placeholder="Razão Social"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.razaoSocial"
                name="razao-social"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Inscrição Estadual"
            label-for="inscricao-estadual"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.inscricaoEstadual"
                name="inscricao-estadual"
                placeholder="Inscrição Estadual"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.inscricaoEstadual"
                name="inscricap-estadual"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Telefone"
            label-for="telefone"
          >
            <div v-if="editar">
              <cleave
                v-model="dadoCliente.telefone"
                class="form-control"
                :raw="false"
                :options="mask.telefone"
                name="telefone"
                placeholder="Telefone"
              />
            </div>
            <div v-else>
              <cleave
                v-model="dadoCliente.telefone"
                class="form-control"
                :raw="false"
                :options="mask.telefone"
                name="telefone"
                placeholder="Telefone"
                :readonly="!editar"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Celular"
            label-for="celular"
          >
            <div v-if="editar">
              <cleave
                v-model="dadoCliente.celular"
                class="form-control"
                :raw="false"
                :options="mask.telefone"
                name="celular"
                placeholder="Celular"
              />
            </div>
            <div v-else>
              <cleave
                v-model="dadoCliente.celular"
                class="form-control"
                :raw="false"
                :options="mask.telefone"
                name="celular"
                placeholder="Celular"
                :readonly="!editar"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="CEP"
            label-for="cep"
          >
            <div v-if="editar">
              <cleave
                v-model="dadoCliente.cep"
                class="form-control"
                :raw="false"
                :options="mask.cep"
                name="cep"
                placeholder="CEP"
              />
            </div>
            <div v-else>
              <cleave
                v-model="dadoCliente.cep"
                class="form-control"
                :raw="false"
                :options="mask.cep"
                name="cep"
                placeholder="CEP"
                :readonly="!editar"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="8">
          <b-form-group
            label="Endereço"
            label-for="endereco"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.endereco"
                name="endereco"
                placeholder="Endereço"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.endereco"
                name="endereco"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Cidade"
            label-for="cidade"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.cidade"
                name="cidade"
                placeholder="Cidade"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.cidade"
                name="cidade"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <div v-if="editar">
              <div>
                <b-form-checkbox
                  v-model="dadoCliente.status"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div v-else>
              <div>
                <b-form-checkbox
                  v-model="dadoCliente.status"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  disabled
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <div v-if="!editar">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="btEditarCliente"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">Editar</span>
            </b-button>
          </div>
          <div v-else>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="updateCliente"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Salvar</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol,
  // BAlert,
  BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import axios from '../../../../axios-auth'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    BFormCheckbox,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      refInputEl,
      previewEl,
    }
  },
  data() {
    return {
      permissions: null,
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
          uppercase: true,
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        telefone: {
          delimiters: [' ', '-', '-'],
          blocks: [2, 4, 4],
          uppercase: true,
        },
      },
      // eslint-disable-next-line global-require
      logo: '',
      editar: false,
      status: true,
      dadoCliente: this.generalData,
      profileFile: null,
    }
  },
  // created() {
  //   this.permissions = JSON.parse(localStorage.permissoes).credores
  // },
  methods: {
    formatarCPFouCNPJ(valor) {
      if (valor && valor.length === 14) {
      // Formatação para CNPJ
        return valor.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
      }
      return valor
    },
    resetForm() {
      this.dadoCliente = JSON.parse(JSON.stringify(this.generalData))
      this.editar = false
    },
    updateCliente() {
      const formData = {
        nome_fantasia: this.dadoCliente.nomeFantasia,
        cnpj: this.dadoCliente.cnpj.replace(/[^0-9]/g, ''),
        razao_social: this.dadoCliente.razaoSocial,
        inscricao_estadual: this.dadoCliente.inscricaoEstadual,
        telefone: this.dadoCliente.telefone.replace(/[^0-9]/g, ''),
        celular: this.dadoCliente.celular.replace(/[^0-9]/g, ''),
        endereco: this.dadoCliente.endereco,
        cidade: this.dadoCliente.cidade,
        status: this.dadoCliente.status,
        cep: this.dadoCliente.cep,
      }
      axios.put(`api/empresas/update/${this.dadoCliente.id}`, formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.editar = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${res.data.mensagem}`,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Erro ao salvar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    consultaCEP() {
      const cep = this.dadoCliente.cep.replace(/[^0-9]/g, '')
      axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => {
          this.dadoCliente.numero = ''
          this.dadoCliente.rua = res.data.logradouro
          this.dadoCliente.bairro = res.data.bairro
          this.dadoCliente.cidade = res.data.localidade
          this.dadoCliente.uf = res.data.uf
        })
    },
    btEditarCliente() {
      this.editar = true
    },
  },
}
</script>
