<template>
  <div>
    <!-- Stats Card Horizontal -->
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ActivityIcon"
          color="primary"
          :statistic="data[0].total"
          statistic-title="Total"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CalendarIcon"
          color="warning"
          :statistic="data[0].agendar"
          statistic-title="Agendar"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="PhoneOffIcon"
          color="secondary"
          :statistic="data[0].sem_contato"
          statistic-title="Sem Contato"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="MessageSquareIcon"
          color="success"
          :statistic="data[0].em_atendimento"
          statistic-title="Em Atendimento"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ClipboardIcon"
          color="warning"
          :statistic="data[0].laudo"
          statistic-title="Laudo"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ToolIcon"
          color="secondary"
          :statistic="data[0].em_reparo"
          statistic-title="Em Reparo"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AwardIcon"
          color="primary"
          :statistic="data[0].reparo_concluido"
          statistic-title="Reparo Concluído"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="TruckIcon"
          color="success"
          :statistic="data[0].reparo_entregue"
          statistic-title="Reparo Entregue"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="RefreshCwIcon"
          color="primary"
          :statistic="data[0].troca"
          statistic-title="Trocado"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ThumbsDownIcon"
          color="danger"
          :statistic="data[0].negado"
          statistic-title="Negado"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="XSquareIcon"
          color="danger"
          :statistic="data[0].cancelado"
          statistic-title="Cancelado"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CornerLeftUpIcon"
          color="info"
          :statistic="data[0].reincidencia"
          statistic-title="Reincidência"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Area charts
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
    }
  },
  created() {
    // Subscribers gained
    this.$http.get('/card/card-statistics/subscribers')
      .then(response => { this.subscribersGained = response.data })

    // Revenue Generated
    this.$http.get('/card/card-statistics/revenue')
      .then(response => { this.revenueGenerated = response.data })

    // Sales
    this.$http.get('/card/card-statistics/sales')
      .then(response => { this.quarterlySales = response.data })

    // Orders
    this.$http.get('/card/card-statistics/orders')
      .then(response => { this.ordersRecevied = response.data })

    // Site Traffic gained
    this.$http.get('/card/card-statistics/site-traffic')
      .then(response => { this.siteTraffic = response.data })

    // Active Users
    this.$http.get('/card/card-statistics/active-users')
      .then(response => { this.activeUsers = response.data })

    // Newsletter
    this.$http.get('/card/card-statistics/newsletter')
      .then(response => { this.newsletter = response.data })
  },
  methods: {
    filtrar() {

    },
    kFormatter,
  },
}
</script>
