<template>
  <b-card class="card-congratulation-medal">
    <h5>Filtros</h5>
    <label>Data Inicio</label>
    <b-form-input
      id="abertura-inicio"
      v-model="abertura.inicio"
      locale="pt"
      type="date"
    />
    <label>Data Final</label>
    <b-form-input
      id="abertura-final"
      v-model="abertura.final"
      locale="pt"
      type="date"
    />
    <label> Seguradora</label>
    <v-select
      v-model="seguradora.selected"
      :options="seguradora.options"
      multiple
    />
    <label>Revenda</label>
    <v-select
      v-model="revenda.selected"
      :options="revenda.options"
      multiple
    />
    <label>Marca</label>
    <v-select
      v-model="marca.selected"
      :options="marca.options"
      multiple
    />
    <label>Linha</label>
    <v-select
      v-model="linha.selected"
      :options="linha.options"
      multiple
    />
    <label>Tipo Atendimento</label>
    <v-select
      v-model="tipoAtendimento.selected"
      :options="tipoAtendimento.options"
      multiple
    />
    <label>Status Atendimento</label>
    <v-select
      v-model="statusAtendimento.selected"
      :options="statusAtendimento.options"
      multiple
    />
    <label>Atendente</label>
    <v-select
      v-model="atendente.selected"
      :options="atendente.options"
      multiple
    />
    <label>Região</label>
    <v-select
      v-model="regiao.selected"
      :options="regiao.options"
      multiple
    />
    <label>Estado</label>
    <v-select
      v-model="estado.selected"
      :options="estado.options"
      multiple
      @input="getCidadesPorEstado"
    />
    <label>Cidade</label>
    <v-select
      v-model="cidade.selected"
      :options="cidade.options"
      multiple
    />
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mt-1"
      variant="primary"
      @click="filtrar"
    >
      Filtrar
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '../../../../axios-auth'

export default {
  components: {
    BCard,
    BButton,
    vSelect,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      seguradora: {
        selected: [],
        options: [],
      },
      revenda: {
        selected: [],
        options: [],
      },
      marca: {
        selected: [],
        options: [],
      },
      linha: {
        selected: [],
        options: [],
      },
      tipoAtendimento: {
        selected: [],
        options: [
          'NORMAL',
          'PRIORITÁRIO',
          'EMERGENCIAL',
        ],
      },
      statusAtendimento: {
        selected: [],
        options: [],
      },
      atendente: {
        selected: [],
        options: [],
      },
      regiao: {
        selected: [],
        options: [],
      },
      estado: {
        selected: [],
        options: [],
      },
      cidade: {
        selected: [],
        options: [],
      },
      abertura: {
        inicio: '',
        final: '',
      },
      dados: null,
    }
  },
  created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getSeguradoras()
    this.getRevendas()
    this.getMarcas()
    this.getLinhas()
    this.getAtendentes()
    this.getStatusOs()
    this.getRegiao()
    this.getEstado()
    this.filtrar()
  },
  methods: {
    emitDataToParent() {
      // Emitir o evento 'informacao-para-pai' com os dados que queremos enviar
      this.$emit('informacao-para-pai', 'Dados enviados do componente filho')
    },
    clearFiltro() {
      this.seguradora.selected = []
      this.revenda.selected = []
      this.marca.selected = []
      this.linha.selected = []
      this.tipoAtendimento.selected = []
      this.statusAtendimento.selected = []
      this.atendente.selected = []
      this.regiao.selected = []
      this.estado.selected = []
      this.cidade.selected = []
      this.abertura.inicio = ''
      this.abertura.final = ''
    },
    getSeguradoras() {
      this.seguradora.options = []
      axios
        .get(`api/empresas/seguradoras/${this.$route.params.id}`)
        .then(res => {
          res.data.dados.map(item => {
            this.seguradora.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getRevendas() {
      this.revenda.options = []
      axios
        .get(`api/empresas/revendas/${this.$route.params.id}`)
        .then(res => {
          res.data.dados.map(item => {
            this.revenda.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getMarcas() {
      this.marca.options = []
      axios
        .get(`api/empresas/marcas/${this.$route.params.id}`)
        .then(res => {
          res.data.dados.map(item => {
            this.marca.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getLinhas() {
      this.linha.options = []
      axios
        .get(`api/empresas/linhas/${this.$route.params.id}`)
        .then(res => {
          res.data.dados.map(item => {
            this.linha.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getAtendentes() {
      this.atendente.options = []
      axios
        .get(`api/empresas/atendentes/${this.$route.params.id}`)
        .then(res => {
          res.data.dados.map(item => {
            this.atendente.options.push({
              label: item.name,
              id: item.id,
            })
          })
        })
    },
    getStatusOs() {
      this.statusAtendimento.options = []
      axios
        .get('api/ordem_servico/listar/status_os')
        .then(res => {
          res.data.dados.map(item => {
            this.statusAtendimento.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getRegiao() {
      this.regiao.options = []
      axios
        .get('api/localidade/regiao')
        .then(res => {
          res.data.dados.map(item => {
            this.regiao.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getEstado() {
      this.estado.options = []
      axios
        .get('api/localidade/estado')
        .then(res => {
          res.data.dados.map(item => {
            this.estado.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getCidadesPorEstado() {
      const estadoId = this.estado.selected.map(e => e.id)
      this.getCidade(estadoId)
    },
    getCidade(id) {
      this.cidade.options = []
      axios
        .get(`api/localidade/cidade/${id}`)
        .then(res => {
          res.data.dados.map(item => {
            this.cidade.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    filtrar() {
      this.$emit('informacao-para-pai', 1)
      this.nameFile = ''
      let body = {}
      body = {
        empresa_id: this.$route.params.id,
        filtros: {},
        master: true,
      }
      if (this.abertura.inicio && this.abertura.final) {
        body.filtros.abertura = {
          data_inicial: `${this.abertura.inicio} 00:00:00`,
          data_final: `${this.abertura.final} 23:59:59`,
        }
      }
      if (this.seguradora.selected.length) {
        const seguradores = this.seguradora.selected.map(item => item.id)
        body.filtros.seguradora = seguradores
      }
      if (this.revenda.selected.length) {
        const revendas = this.revenda.selected.map(item => item.id)
        body.filtros.revenda = revendas
      }
      if (this.linha.selected.length) {
        const linhas = this.linha.selected.map(item => item.id)
        body.filtros.linha = linhas
      }
      if (this.marca.selected.length) {
        const marcas = this.marca.selected.map(item => item.id)
        body.filtros.marca = marcas
      }
      if (this.tipoAtendimento.selected.length) {
        body.filtros.tipoAtendimento = this.tipoAtendimento.selected
      }
      if (this.statusAtendimento.selected.length) {
        const atendimentos = this.statusAtendimento.selected.map(item => item.id)
        body.filtros.statusAtendimento = atendimentos
      }

      if (this.atendente.selected.length) {
        const atendentes = this.atendente.selected.map(item => item.id)
        body.filtros.atendente = atendentes
      }
      if (this.regiao.selected.length) {
        const regioes = this.regiao.selected.map(item => item.id)
        body.filtros.regiao = regioes
      }

      if (this.estado.selected.length) {
        const estado = this.estado.selected.map(item => item.id)
        body.filtros.estado = estado
      }
      if (this.cidade.selected.length) {
        const cidade = this.cidade.selected.map(item => item.id)
        body.filtros.cidade = cidade
      }
      this.$emit('informacao-para-pai', body)
      axios
        .post(`api/empresas/master/total_status/${this.$route.params.id}`, body)
        .then(res => {
          this.$emit('informacao-para-pai', res.data.dados)
        })
        .catch(error => {
          this.clearFiltro()
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    calculoIndicadores(dados) {
      const { json } = dados

      const mapDataset = []
      // eslint-disable-next-line consistent-return
      dados.os.forEach(os => {
        if (!os.latitude || !os.longitude) return null
        mapDataset.push({
          nome: os.cidade_nome,
          latitude: os.latitude,
          longitude: os.longitude,
        })
      })

      const qtOs = dados.os.length
      let valorTotalSeguradora6 = 0
      let valorTotalSeguradora9 = 0
      let qtS6 = 0
      let qtS9 = 0
      let qtOSFinalizada = 0
      let somaQDA = 0
      let qtReclamacao = 0
      let somaIS = 0

      // [reparabilidade, troca, reincidência]
      const reparoEntregueArray = [0, 0, 0]

      dados.os.map(o => {
        if (o.reclamacao === 1) {
          qtReclamacao += 1
        }
        if (o.status_os_id === 6 || o.status_os_id === 7 || o.status_os_id === 9) {
          qtOSFinalizada += 1
          somaQDA += o.dias_em_aberto
          if (o.produto_valor) {
            somaIS += parseFloat(o.produto_valor)
          }
        }
        if (o.status_os_id === 6) {
          qtS6 += 1
          reparoEntregueArray[0] += 1
          if (o.valor_total_seguradora) {
            valorTotalSeguradora6 += parseFloat(o.valor_total_seguradora)
          }
        }
        if (o.status_os_id === 9) {
          reparoEntregueArray[1] += 1
          qtS9 += 1
          if (o.valor_total_seguradora) {
            valorTotalSeguradora9 += parseFloat(o.valor_total_seguradora)
          }
        }

        if (o.status_os_id === 13) {
          reparoEntregueArray[2] += 1
        }
      })

      const calcReparoEntregue = () => {
        const array = reparoEntregueArray
        const sum = array.reduce((a, b) => a + b, 0)
        if (sum === 0) {
          return [0, 0, 0]
        }
        // eslint-disable-next-line no-mixed-operators
        const percentual = array.map(value => (value * 100 / sum).toFixed(2))

        return percentual
      }

      const [REP, PT, REIN] = calcReparoEntregue()

      const vCMG = parseFloat(valorTotalSeguradora6) + parseFloat(valorTotalSeguradora9)
      const tCMG = parseInt(qtS6) + parseInt(qtS9)
      const CMR = valorTotalSeguradora6 / qtS6
      const CMG = vCMG / tCMG
      const TAT = somaQDA / qtOSFinalizada
      const REC = (qtReclamacao / qtOs) * 100
      const LMI = (CMG / somaIS) * 100
      const Dados = {
        json,
        qtOs,
        CMR,
        CMG,
        TAT,
        PT,
        REP,
        REIN,
        REC,
        LMI,
        mapDataset,
      }
      this.dados = Dados
      this.emitEvent()
    },
  },
}

</script>
