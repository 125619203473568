<template>
  <div>
    <b-row
      align-h="end"
      class="mb-1"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$refs.administradoresModal.show()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          Administradores
        </b-button>
      </b-col>
    </b-row>
    <b-card title="Administradores">
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-table
          small
          striped
          responsive
          :fields="fields"
          :items="administradores"
        >
          <template #cell(status)="data">
            <b-badge :variant="data.item.status === 1 ? 'success' : 'danger'">
              {{ data.item.status === 1 ? 'ATIVO' : 'INATIVO' }}
            </b-badge>
          </template>
          <template #cell(opcoes)="data">
            <b-row>
              <b-col cols="auto">
                <feather-icon
                  icon="EditIcon"
                  class="color-icon"
                  @click="detailAdministradores(data.item)"
                />
              </b-col>
              <b-col cols="auto">
                <feather-icon
                  icon="Trash2Icon"
                  class="trash-icon"
                  @click="deleteAdministradores(data.item)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
    <b-modal
      ref="administradoresModal"
      hide-footer
      no-close-on-backdrop
      @hide="clearModal"
    >
      <b-row class="mb-2">
        <b-col
          cols="12"
          class="mt-1"
        >
          Nome:
          <b-form-input
            v-model="cad.name"
            placeholder="Nome"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          CPF:
          <cleave
            v-model="cad.cpf_cnpj"
            class="form-control"
            :raw="false"
            :options="{numericOnly: true, ...mask.cpf}"
            placeholder="CPF"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Email:
          <b-form-input
            v-model="cad.email"
            placeholder="Email"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Senha:
          <b-form-input
            v-model="cad.password"
            placeholder="********"
            required
          />
        </b-col>
      </b-row>
      <hr>
      <b-row align-h="end">
        <b-col
          v-if="!editModal"
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="storeAdministradores"
          >
            Cadastrar
          </b-button>

        </b-col>
        <b-col
          v-if="editModal"
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="updateAdministradores"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BFormInput,
  BModal,
  BBadge,
  BOverlay,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BFormInput,
    BModal,
    BBadge,
    Cleave,
    BOverlay,

  },
  data() {
    return {
      editModal: false,
      idAdministradores: '',
      cad: {
        name: '',
        email: '',
        cpf_cnpj: '',
        password: '',
        empresa_id: this.$route.params.id,
        perfil_id: 1, // id do perfil admin
        funcao_id: 2, // id da funcao admin
      },
      mask: {
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
      },
      // 'name',
      //   'email',
      //   'status',
      //   'opcoes',
      fields: [
        { key: 'name', label: 'nome', class: 'text-center' },
        { key: 'email', label: 'email', class: 'text-center' },
        { key: 'status', label: 'status', class: 'text-center' },
        { key: 'opcoes', label: 'opções', class: 'text-center' },
      ],
      administradores: [],
      load: true,
    }
  },
  created() {
    this.getAdministradores()
  },
  methods: {
    clearModal() {
      this.cad = {
        name: '',
        email: '',
        cpf_cnpj: '',
        password: '',
      }
    },
    updateAdministradores() {
      this.load = true
      this.cad.cpf_cnpj = this.soNumero(this.cad.cpf_cnpj)

      axios.put(`api/users/update/${this.idAdministradores}`, this.cad, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(() => {
        this.$refs.administradoresModal.hide()
        this.getAdministradores()
        this.$swal({
          title: 'Administrador atualizado!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
    },
    detailAdministradores(item) {
      this.load = true
      axios.get(`api/users/edit/${item.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        const dt = res.data.dados

        this.cad = {
          name: dt.name,
          email: dt.email,
          cpf_cnpj: dt.cpf_cnpj,
          password: dt.password,
          empresa_id: dt.empresa_id,
          perfil_id: dt.perfil_id,
          funcao_id: dt.funcao_id,
        }
        this.idAdministradores = item.id
        this.editModal = true
        this.load = false
        this.$refs.administradoresModal.show()
      })
    },
    deleteAdministradores(item) {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero desativar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.load = true
          axios.get(`api/users/desativar/${item.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }).then(() => {
            this.getAdministradores()
            this.$swal({
              title: 'Administrador Desativado!',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
            })
          })
            .catch(error => {
              this.$swal({
                title: 'ERRO',
                icon: 'error',
                text: `Ocorreu um erro: ${error.response.data.mensagem}`,
              })
            })
        }
      })
    },
    storeAdministradores() {
      this.load = true
      axios.post('api/users/store', {
        name: this.cad.name,
        email: this.cad.email,
        cpf_cnpj: this.soNumero(this.cad.cpf_cnpj),
        password: this.cad.password,
        empresa_id: this.$route.params.id,
        perfil_id: 1, // id do perfil admin
        funcao_id: 2, // id da funcao admin
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.$refs.administradoresModal.hide()
        this.getAdministradores()
        this.$swal({
          title: 'Administrador Cadastrado!',
          icon: 'success',
          text: 'Novo cadastro adicionado!',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(error => {
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: `Ocorreu um erro durante o cadastro: ${error.response.data.mensagem} `,
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
    },
    getAdministradores() {
      this.administradores = []
      axios.get(`api/empresas/administradores/${this.$route.params.id}`).then(res => {
        this.administradores = res.data.dados
        this.load = false
      })
    },
    soNumero(valor) {
      return valor.replace(/\D/g, '')
    },
  },
}
</script>
